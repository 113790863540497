import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";
import { toast } from "react-hot-toast";
import axios from "axios";

export default function DeletePopUp({ open, handleClose, call, data }) {
  const handleDelete = () => {
    toast.loading("deleting ",data?.name)
    console.log(data);
    axios.delete(`${process.env.REACT_APP_ADMIN_API}/courses?id=${data?._id}`).then((res)=>{
        console.log(res.data)
        toast.remove()
        toast.success("course deleted")
        handleClose()
        call()
    }).catch(e=>{
        console.log(e?.response.data)
        toast.error("Delete course details failed , try again")
    })
  };
  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Are You sure want to delete this Course?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography variant="h6" >{data?.name}</SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button color="success" onClick={handleDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

DeletePopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  call: PropTypes.func.isRequired,
  data: PropTypes.isRequired,
};
