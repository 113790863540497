import axios from "axios";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import moment from "moment";
import { useEffect, useState } from "react";

export default function JobApplications() {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/all-job-applications`)
      .then((res) => {
        setData(res?.data)
      })
      .catch((e) => console.log(e));
  }, []);

  const columns = [
    { name: "user", align: "left" },
    { name: "email", align: "left" },
    { name: "phone", align: "left" },
    { name: "job", align: "center" },
    { name: "date", align: "center" },
  ];
  const rows = data?.map((res) => {
    return ({
      user:  (
        <SoftTypography padding={2} variant="caption" color="secondary" fontWeight="medium">
         {res?.userid?.name}
        </SoftTypography>
      ),
      email: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
         {res?.userid?.email}
        </SoftTypography>
      ),
      phone: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
         {res?.userid?.phone}
        </SoftTypography>
      ),
      date:(
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
         {moment(res?.createdAt).format('lll')}
        </SoftTypography>
      ),
      job: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
         {res?.jobId?.title}
        </SoftTypography>
      ),
    });
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>Job applications</SoftBox>
      <SoftBox mb={2} >
      {data && <Table columns={columns} rows={rows} />}
      </SoftBox>
      <Footer  />
    </DashboardLayout>
  );
}
