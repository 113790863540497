export const API = 'https://academicpathwayexperts.com/api'
// export const API = 'http://localhost:5000'
// export const ADMIN_API = 'http://localhost:5000/admin'
export const ADMIN_API = 'https://academicpathwayexperts.com/api/admin'

export const IMAGE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI0MWJlZjk4YS1hYmY2LTQxNmYtYjUyZC1lOGE1N2NkNDY4ZGIiLCJlbWFpbCI6Im11bm5hc2FqZWV2NUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiMjA4MjlhMTBiNWQyNTdmYzlhZjciLCJzY29wZWRLZXlTZWNyZXQiOiI3N2JjNTE4ZWVmZmQ3YjI5YTQwNzgyOTI1NDQ0YmI3ZjFmNzYxNmQxYzJmMGUxYzJiZDM2MzRkZGM1M2NmNDFiIiwiaWF0IjoxNzAyNzUyMDEwfQ.1YuNO9Gyfo4XN6kWJpbTjLYv4YeCU7SejQewc7IzRfA"

export const VIEW ="https://magenta-secondary-rhinoceros-679.mypinata.cloud/ipfs/"
export const UPLOAD = "https://api.pinata.cloud/pinning/pinFileToIPFS"

